function formatDate(date: Date): string {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}

export function formatStartDate(dateStr: string, includeTime: boolean | undefined = false): string {
  const date = new Date(dateStr);
  return includeTime
    ? `${formatDate(date)} | ${date.toLocaleTimeString([], { timeStyle: 'short' })}`
    : formatDate(date);
}

export function startAndEndDate(startDate: string, endDate: string) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  return `${formatDate(start)} - ${formatDate(end)}`;
}

export function dateInFuture(days: number): Date {
  const today = new Date();
  const nextWeek = new Date(today.getTime() + days * 24 * 60 * 60 * 1000);
  return nextWeek;
}

export function getFutureTime(countDownDate: number) {
  const now = new Date().getTime();
  const distance = countDownDate - now;

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  return {
    days,
    hours,
    minutes,
    seconds,
    distance,
  };
}
